
              /** @type {import('tailwindcss').Config} */
              module.exports = {
                content: [
                  './pages/**/*.{js,ts,jsx,tsx,mdx}',
                  './components/**/*.{js,ts,jsx,tsx,mdx}',
                  './app/**/*.{js,ts,jsx,tsx,mdx}',
                ],
                theme: {
                  extend: {
                    backgroundImage: {
                      'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
                      'gradient-conic':
                        'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
                    },
                    colors: {
                      'maincolor': '#1b2f6b',
                      'maincolortrans': '#2331424d',
                      // 'secondary': '#015B97',
                      'secondary': '#05294d',
                      'third': '#941109',
                      'forth': '#e3e3e3',
                      'light': 'white',
                      'navlight': '#D3D3D3',
                      'mainhover': 'gray'

                    },
                    backgroundColor: ['hover'],
                  },
                },
                plugins: [],
              }